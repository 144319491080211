<template>
  <div>
    <v-form ref="form" v-model="valid" class="form-login px-4" lazy-validation>
      <v-row class="align-center">
        <v-col class="flex-row align-center pt-8 pb-2" cols="12">
          <div class="font-size-text line-height-title purple-dark--text font-weight-bold">
            {{ $t('advisorForm.contractingDetails') }}
          </div>
        </v-col>
      </v-row>

      <v-row class="align-center bottom-border">
        <v-col class="flex-row align-center pt-8" cols="12">
          <v-text-field
            :label="$t('advisorForm.email')"
            height="54"
            class="card-text-field-border label-class"
            ref="emb"
            :rules="rules.rulesEmail"
            dense
            outlined
            v-model="petition.resort.contracting_email"
            flat
            background-color="white"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="bottom-border">
        <v-col class="d-flex align-center pt-8 pb-0 mb-n4" cols="12">
          <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
            {{ $t('gender') }}
          </div>
        </v-col>
        <v-col class="d-flex flex-column py-7" cols="12">
          <v-btn-toggle
            v-model="petition.resort.contracting.gender"
            color="secondary"
            class="d-flex full-width mb-1"
          >
            <v-btn
              width="50%"
              value="male"
              text
              class="toggle-buttons text-none"
            >
              <v-icon
                :color="
                  petition.resort.contracting.gender == 'male'
                    ? 'white'
                    : 'black'
                "
                size="24"
                class="mr-2"
                >mdi-gender-male</v-icon
              >
              {{ $t('male') }}</v-btn
            >
            <v-btn
              width="50%"
              value="female"
              text
              class="toggle-buttons text-none"
            >
              <v-icon
                :color="
                  petition.resort.contracting.gender == 'female'
                    ? 'white'
                    : 'black'
                "
                size="24"
                class="mr-1"
                >mdi-gender-female</v-icon
              >
              {{ $t('female') }}
            </v-btn>
          </v-btn-toggle>
          <div
            v-if="$refs.gender && $refs.gender.hasError"
            class="v-text-field__details pt-14 ml-3 p-absolute"
          >
            <div class="v-messages theme--light error--text" role="alert">
              <div class="v-messages__wrapper">
                <div class="v-messages__message">
                  {{ $refs.gender.errorBucket[0] }}
                </div>
              </div>
            </div>
          </div>
          <v-radio-group
            ref="gender"
            :rules="rules.rulesOthers"
            v-model="petition.resort.contracting.gender"
            class="toggle-buttons d-none mt-0"
            color="primary"
          >
            <div class="d-flex flew-row align-center pt-2">
              <v-radio
                value="male"
                :label="$t('male')"
                text
                fab
                class="text-none pa-0 purple-dark--text font-weight-bold pt-2 mr-3"
              ></v-radio>

              <v-radio
                value="female"
                :label="$t('female')"
                text
                fab
                class="purple-dark--text font-weight-bold text-none ml-5 mr-3"
              >
              </v-radio>
            </div>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row align-content="start">
        <v-col class="pb-0 px-3" md="6" cols="12">
          <v-col
            class="pl-1 flex-row align-center mt-0 mt-md-5 mb-0 mb-md-n8"
            cols="12"
          >
            <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
              {{ $t('name') }}
            </div>
          </v-col>
          <v-col class="pl-0 pr-0 d-flex align-center pt-0 pt-md-8" cols="12">
            <v-text-field
              v-uppercase
              v-model="petition.resort.contracting.name"
              height="54"
              class="card-text-field-border label-class"
              :rules="rules.rulesOthers"
              dense
              outlined
              flat
              background-color="white"
            ></v-text-field> </v-col
        ></v-col>
        <v-col class="py-0 py-md-3 px-0" md="6" cols="12">
          <v-col
            class="pl-5 flex-row align-center mt-0 mt-md-5 mb-md-n8"
            cols="12"
          >
            <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
              {{ $t('surname') }}
            </div>
          </v-col>
          <v-col class="px-3 d-flex align-center pt-0 pt-md-8" cols="12">
            <v-text-field
              v-uppercase
              v-model="petition.resort.contracting.surname"
              height="54"
              class="card-text-field-border label-class"
              :rules="rules.rulesOthers"
              dense
              outlined
              flat
              background-color="white"
            ></v-text-field> </v-col
        ></v-col>
      </v-row>

      <v-row class="align-center">
        <v-col class="py-0" cols="12">
          <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
            {{ $t('fathersName') }}
          </div>
        </v-col>
        <v-col class="flex-row align-center" cols="12">
          <v-text-field
            v-uppercase
            v-model="petition.resort.contracting.fathersName"
            height="54"
            class="card-text-field-border label-class"
            :rules="rules.rulesOthers"
            dense
            outlined
            flat
            background-color="white"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12">
          <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
            {{ $t('birthDate') }}
          </div>
        </v-col>
        <v-col class="d-flex align-center" cols="12">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            nudge-bottom="90"
            :max-width="$vuetify.breakpoint.lgAndUp ? 664 : 'auto'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                readonly
                height="54"
                class="card-text-field-border label-class"
                :rules="rules.rulesYear"
                dense
                name="birth-date"
                v-model="petition.resort.contracting.birthDate"
                autocomplete="birth-date-new"
                outlined
                v-bind="attrs"
                v-on="on"
                flat
                background-color="white"
              ></v-text-field>
            </template>
            <v-date-picker
              no-title
              ref="picker"
              :locale="$i18n.locale == 'en' ? 'en' : 'el'"
              v-model="date"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save"
              full-width
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="align-center">
        <v-col class="py-0" cols="12">
          <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
            {{ $t('phone') }}
          </div>
        </v-col>
        <v-col class="flex-row align-center" cols="12">
          <v-text-field
            v-model="petition.resort.contracting.phone"
            height="54"
            class="card-text-field-border label-class"
            :rules="rules.rulesPhone"
            dense
            outlined
            flat
            background-color="white"
          ></v-text-field>
        </v-col>
        <v-col class="py-0" cols="12">
          <div class="font-size-card-title line-height-title purple-dark--text font-weight-bold">
            {{ $t('afm') }}
          </div>
        </v-col>
        <v-col class="d-flex align-center" cols="12">
          <v-text-field
            v-model="petition.resort.contracting.afm"
            height="54"
            class="card-text-field-border label-class"
            :rules="rules.rulesAFM"
            dense
            outlined
            flat
            background-color="white"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar
      rounded="lg"
      elevation="0"
      top
      color="#FFF0EF"
      class="error-snackbar"
      light
      v-model="snackbar"
    >
      <v-icon color="error">mdi-information-outline</v-icon
      ><span class="error--text ml-2">
        {{ $t('advisorForm.pleaseFillOutTheForm') }}</span
      >
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import rules from '@/helpers/validation-rules-owner';
import dayjs from 'dayjs';

export default {
  name: 'AdvisorData',
  data() {
    return {
      date: null,
      menu: false,
      snackbar: false,
      valid: true,
      test: '',
      rules: {},
    };
  },
  computed: {
    ...mapGetters(['petition', 'price']),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  components: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.internalActivePicker = 'YEAR'));
    },
    petition: {
      deep: true,
      handler() {
        this.rules = {};
      },
    },
  },
  methods: {
    ...mapMutations(['setPrice', 'setLoading']),
    formatDate(date) {
      if (!date) return null;

      return dayjs(date).format('DD/MM/YYYY');
    },
    save(date) {
      this.petition.resort.contracting.birthDate = this.computedDateFormatted;
      this.$refs.menu.save(date);
    },
    previousPage() {
      this.$router.push('/about-property');
    },
    nextPage() {
      this.rules = rules(this);
      this.$nextTick(() => {
        if (!this.$refs.form.validate()) {
          return (this.snackbar = true);
        }
        this.setLoading(true);

        this.$axios
          .post(`get-quotation-service`, this.petition)
          .then(({ data }) => {
            this.setPrice(data);
            this.setLoading(false);

            this.$router.push({ name: 'Checkout' });
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err);
            this.setLoading(false);
          });
      });
    },
  },
};
</script>
