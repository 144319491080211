import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/customParseFormat'));

export default context => {
  return {
    rulesEmail: [
      value => !!value || context.$t('rules.required'),
      v =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        context.$t('rules.email'),
    ],
    rulesOthers: [value => !!value || context.$t('rules.required')],
    rulesYear: [
      value => !!value || context.$t('rules.required'),
      value =>
        dayjs().diff(dayjs(value, 'DD/MM/YYYY'), 'years') >= 18 ||
        context.$t('rules.year'),
    ],
    rulesPhone: [
      value => !!value || context.$t('rules.required'),
      value =>
        /^\s*(?:\+30?)?[-. (]*(69\d{1})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
          value,
        ) || context.$t('rules.phone'),
    ],
    rulesAFM: [
      value => !!value || context.$t('rules.required'),
      value => (value && value.length == 9) || context.$t('rules.afm'),
      value => value <= 999999999 || context.$t('rules.afm'),
    ],
    percentageRules: [
      value => !!value || context.$t('rules.required'),
      () => {
        if (context.userIndex + 1 == context.petition.resort.ownerNumber) {
          return (
            (context.percentages > 0 && context.percentages == 100) ||
            context.$t('rules.percentageSum')
          );
        }

        return (
          (context.percentages > 0 && context.percentages <= 100) ||
          context.$t('rules.percentage')
        );
      },
    ],
  };
};
